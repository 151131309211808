<template>
  <div data-layout="site/legal" class="bg-forgd-bgd-200 font-display legal-pages min-h-screen flex flex-col justify-between">
    <div>
      <header class="flex justify-center items-center border-b border-forgd-bgd-600 py-10 mb-10 mx-16">
        <NuxtLink :to="webflowLink('/')">
          <LogoForgd />
        </NuxtLink>
      </header>

      <main>
        <slot />
      </main>
    </div>

    <footer class="border-t border-forgd-bgd-600 px-16 py-11 flex justify-between items-center text-forgd-primary-900">
      <div class="flex space-x-9 items-center text-sm">
        <a href="#">Gitbook</a>

        <a href="#">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1400_49894)">
              <path d="M14.8189 0H1.18111C0.867861 0 0.567441 0.124438 0.34594 0.34594C0.124438 0.567441 0 0.867861 0 1.18111V14.8189C0 15.1321 0.124438 15.4326 0.34594 15.6541C0.567441 15.8756 0.867861 16 1.18111 16H14.8189C15.1321 16 15.4326 15.8756 15.6541 15.6541C15.8756 15.4326 16 15.1321 16 14.8189V1.18111C16 0.867861 15.8756 0.567441 15.6541 0.34594C15.4326 0.124438 15.1321 0 14.8189 0ZM4.76889 13.63H2.36333V5.98889H4.76889V13.63ZM3.56445 4.93C3.29158 4.92846 3.02528 4.84613 2.79916 4.69339C2.57304 4.54065 2.39723 4.32435 2.29392 4.07179C2.19061 3.81923 2.16443 3.54173 2.21869 3.2743C2.27294 3.00688 2.4052 2.76152 2.59877 2.56919C2.79234 2.37686 3.03854 2.24618 3.30631 2.19364C3.57408 2.1411 3.85141 2.16906 4.1033 2.27399C4.35519 2.37892 4.57036 2.55611 4.72164 2.78321C4.87293 3.01031 4.95355 3.27713 4.95333 3.55C4.95591 3.73269 4.92167 3.91403 4.85267 4.0832C4.78368 4.25238 4.68132 4.40593 4.55171 4.53471C4.42211 4.66349 4.2679 4.76486 4.09828 4.83277C3.92867 4.90068 3.74711 4.93375 3.56445 4.93ZM13.6356 13.6367H11.2311V9.46222C11.2311 8.23111 10.7078 7.85111 10.0322 7.85111C9.31889 7.85111 8.61889 8.38889 8.61889 9.49333V13.6367H6.21333V5.99445H8.52667V7.05333H8.55778C8.79 6.58333 9.60333 5.78 10.8444 5.78C12.1867 5.78 13.6367 6.57667 13.6367 8.91L13.6356 13.6367Z" fill="#001221" />
            </g>
            <defs>
              <clipPath id="clip0_1400_49894">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </a>

        <a href="#">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.03168 14.5005C11.0694 14.5005 14.3718 9.49823 14.3718 5.16031C14.3718 5.01823 14.3718 4.87679 14.3622 4.73599C15.0047 4.27129 15.5593 3.69591 16 3.03679C15.4009 3.30239 14.7654 3.47649 14.1146 3.55327C14.7999 3.14306 15.3128 2.49779 15.5578 1.73759C14.9134 2.11999 14.2084 2.38947 13.4733 2.53439C12.9783 2.0081 12.3237 1.65961 11.6108 1.54284C10.8978 1.42607 10.1663 1.54753 9.52931 1.88842C8.89234 2.22931 8.38548 2.77064 8.08716 3.42862C7.78884 4.0866 7.71569 4.82456 7.87904 5.52831C6.57393 5.46284 5.29717 5.12366 4.13164 4.53279C2.9661 3.94192 1.93784 3.11256 1.1136 2.09855C0.693819 2.82121 0.565248 3.6767 0.754066 4.49083C0.942885 5.30496 1.43489 6.01652 2.12992 6.48063C1.60749 6.46532 1.09643 6.32438 0.64 6.06975V6.11135C0.640207 6.86925 0.902567 7.60374 1.38258 8.19026C1.86259 8.77677 2.53071 9.17919 3.2736 9.32927C2.79032 9.46109 2.28325 9.48036 1.79136 9.38559C2.00121 10.0378 2.40962 10.6081 2.95949 11.0169C3.50937 11.4256 4.17322 11.6523 4.85824 11.6653C4.17763 12.2003 3.39821 12.5958 2.56458 12.8293C1.73096 13.0627 0.859476 13.1296 0 13.0259C1.50122 13.9893 3.24795 14.5003 5.03168 14.4979" fill="#001221" />
          </svg>
        </a>
      </div>
      <div class="text-xs">
        &copy; {{ new Date().getFullYear() }} Forgd. All rights reserved.
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
  .legal-pages {
    h1 {
      @apply text-6xl font-normal text-forgd-primary-900 mb-20;
    }

    h2 {
      @apply text-2xl font-semibold text-forgd-gray-600 mb-8;
    }

    h3 {
      @apply text-xl font-semibold text-forgd-gray-600 mb-8;
    }

    p, ul, ol {
      @apply text-base font-normal text-forgd-gray-600 mb-8;
    }

    ul, ol {
      @apply ml-8;
    }

    section {
      @apply mb-16;
      a {
        @apply text-forgd-primary-300 font-semibold;
      }
    }

    main {
      @apply max-w-[860px] mx-auto;
    }

    .section-seperator {
      content: url('/icons/seperator.svg');
      @apply my-20;
    }
  }
</style>
<script setup lang="ts">
</script>
